@import url("https://fonts.googleapis.com/css2?family=Gothic+A1:wght@300;400;500;700&display=swap");

// Variables

$color-grey: #949495;
$color-white: #ffffff;
$color-accent: #17c5f1;

$bg-dark: #151515;
$bg-grey: #202022;

$font-size-global: 14px;

// Global

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-family: "Gothic A1", sans-serif;
  font-size: $font-size-global;
  line-height: 1.5;
}

::selection {
  background-color: $color-accent;
  color: $color-white;
}

body {
  background-color: $bg-dark;
  color: $color-grey;
}

a {
  color: inherit;
  text-decoration: inherit;
}

p {
  color: $color-grey;
  margin: 0;
}

h1,
ul {
  color: $color-white;
}

@media only screen and (max-width: 992px) {
  h3 {
    font-size: 1.4rem !important;
  }
}

// Custom

// About

.main {
  max-width: 1440px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  display: flex;

  @media only screen and (max-width: 992px) {
    flex-direction: column;
  }
}

.about {
  position: fixed;
  padding: 88px 0px 88px 88px;
  max-width: 496px;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media only screen and (max-width: 1280px) {
    padding: 32px 0px 32px 32px;
    max-width: 408px;
  }

  @media only screen and (max-width: 992px) {
    position: static;
    padding: 18px;
    width: 100%;
    max-width: 100%;
    height: auto;
  }

  .about__main {
    display: flex;
    flex-direction: column;

    .heading-1 {
      line-height: 1;
      font-size: 4rem;
      font-weight: 600;
      margin: 0;
    }

    .leading {
      display: flex;
      margin-top: 3rem;
      align-items: center;

      .profileImg {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        overflow: hidden;
        flex-shrink: 0;
        margin-right: 2rem;

        img {
          width: 100%;
          object-fit: cover;
        }

        @media only screen and (max-width: 1280px) {
          display: none;
        }
      }

      @media only screen and (max-width: 992px) {
        flex-direction: column;
      }
    }

    nav {
      margin-top: 5rem;
      text-transform: uppercase;
      font-weight: 600;
      display: block;

      ul {
        margin: 0;
        display: flex;
        flex-direction: column;
        list-style: none;
        padding: 0;

        li {
          margin: 1rem 0;

          .navLink {
            display: inline-flex;
            align-items: center;
            color: $color-grey;
            transition-property: background-color, border-color, color, fill,
              stroke, opacity, box-shadow, transform;
            transition: all 0.2s ease-in-out;

            .nav__number {
              letter-spacing: 1px;
              font-size: 0.7rem;
            }

            .nav__line {
              width: 24px;
              height: 1px;
              background-color: $color-grey;
              margin: 0 1.1rem;
              transition-property: background-color, border-color, color, fill,
                stroke, opacity, box-shadow, transform;
              transition: all 0.2s ease-in-out;
            }

            .nav__text {
              letter-spacing: 3px;
              text-transform: uppercase;
              font-size: 0.7rem;
            }

            &:hover {
              color: $color-white;

              .nav__line {
                width: 48px;
                background-color: $color-white;
              }
            }

            &.active {
              color: $color-white;

              .nav__line {
                width: 48px;
                background-color: $color-white;
              }
            }
          }
        }
      }

      @media only screen and (max-width: 992px) {
        display: none;
      }
    }
  }

  .about__social {
    margin-top: 72px;
    width: 100%;
    display: flex;
    align-items: center;

    .profileImg {
      display: none;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 2rem;
      flex-shrink: 0;

      img {
        width: 100%;
        object-fit: cover;
      }
    }

    ul {
      display: flex;
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        margin-right: 2rem;

        a {
          display: flex;
          align-items: center;

          span {
            margin-left: 0.5rem;
            margin-top: 4px;
          }

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    @media only screen and (max-width: 1280px) {
      margin-top: 3rem;

      .profileImg {
        display: block;
      }
    }

    @media only screen and (max-width: 992px) {
      justify-content: center;
    }
  }
}

// Things

.things {
  position: absolute;
  right: 0;
  max-width: 736px;
  padding: 88px 88px 88px 0;

  @media only screen and (max-width: 1280px) {
    padding: 32px 32px 32px 0px;
    max-width: 536px;
  }

  @media only screen and (max-width: 992px) {
    position: static;
    padding: 18px;
    width: 100%;
    max-width: 100%;
  }

  .sectionTitle {
    letter-spacing: 3px;
    text-transform: uppercase;
    color: $color-white;
    padding-top: 24px;
    font-size: 0.8rem;
    font-weight: 600;

    @media only screen and (min-width: 992px) {
      display: none;
    }
  }

  #projects {
    padding-top: 88px;

    @media only screen and (max-width: 992px) {
      padding-top: 3rem;
    }

    .projects__inner {
      margin-top: -96px;

      @media only screen and (max-width: 992px) {
        margin-top: 0;
        padding-top: 1rem;
      }

      &:not(:focus-within):not(:hover) {
        article {
          opacity: 1;
        }
      }

      article {
        margin: 8px 0;
        background-color: $bg-grey;
        transition-property: background-color, border-color, color, fill, stroke,
          opacity, box-shadow, transform;
        transition: all 0.2s ease-in-out;

        @media only screen and (min-width: 992px) {
          opacity: 0.4;
        }

        > a {
          padding: 40px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .tech {
            letter-spacing: 3px;
            text-transform: uppercase;
            font-size: 0.7rem;
            font-weight: 600;
            color: $color-white;
          }

          h3 {
            margin-top: 8px;
            line-height: 1.25;
            font-size: 1.8rem;
            font-weight: 600;
            color: $color-white;
          }

          .text {
            margin-top: 8px;
          }

          .buttons {
            margin-top: 10px;

            a {
              font-size: 0.8rem;
              margin-right: 1rem;

              &:nth-child(1) {
                color: $color-white;
              }

              &:hover {
                text-decoration: underline;
              }
            }
          }

          .projectImg {
            width: 120px;
            height: 120px;
            border-radius: 50%;
            background-color: $bg-grey;
            flex-shrink: 0;
            margin-left: 2rem;
            overflow: hidden;

            img {
              height: 100%;
              object-fit: cover;
              object-position: center;
              width: 100%;
            }

            @media only screen and (max-width: 992px) {
              width: 80px;
              height: 80px;
              display: none;
            }
          }

          @media only screen and (max-width: 992px) {
            padding: 30px;
          }
        }

        @media only screen and (min-width: 992px) {
          &:focus,
          &:hover {
            transform: scale(1.1);
            opacity: 1;
          }

          &:focus ~ &,
          &:hover ~ & {
            opacity: 0.4;
          }
        }
      }
    }
  }

  #playground {
    padding-top: 88px;

    .playground__inner {
      display: flex;
      flex-wrap: wrap;
      margin: -4px;

      &:not(:focus-within):not(:hover) {
        article {
          opacity: 1;
        }
      }

      article {
        width: 320px;
        min-height: 300px;
        margin: 4px;
        flex: none;
        background-color: $bg-grey;
        transition-property: background-color, border-color, color, fill, stroke,
          opacity, box-shadow, transform;
        transition: all 0.2s ease-in-out;
        transform-origin: center;

        @media only screen and (min-width: 992px) {
          opacity: 0.4;
        }

        .article__inner {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
          padding: 40px;
          min-height: inherit;

          .tech {
            letter-spacing: 3px;
            text-transform: uppercase;
            font-size: 0.7rem;
            font-weight: 600;
            color: $color-white;
            display: flex;
            flex-direction: column;

            span {
              margin-bottom: 4px;
            }
          }

          h3 {
            margin-top: 8px;
            line-height: 1.25;
            font-size: 1.8rem;
            font-weight: 600;
            color: $color-white;
          }

          .buttons {
            margin-top: 10px;

            a {
              font-size: 0.8rem;
              margin-right: 1rem;

              &:hover {
                text-decoration: underline;
              }
            }
          }

          @media only screen and (max-width: 992px) {
            padding: 30px;
          }
        }

        &:nth-child(odd) {
          transform: translateY(-27.45px);
          transform-origin: bottom;
        }

        @media only screen and (min-width: 992px) {
          &:focus,
          &:hover {
            transform: scale(1.1);
            opacity: 1;
          }

          &:focus ~ &,
          &:hover ~ & {
            opacity: 0.4;
          }
        }

        @media only screen and (max-width: 992px) {
          width: 100%;
          max-width: 100%;
          margin: 4px 0;

          &:nth-child(odd) {
            transform: translateY(0);
            transform-origin: center;
          }
        }
      }

      @media only screen and (max-width: 992px) {
        margin: 0;
        padding-top: 1rem;
      }
    }
  }

  #contact {
    padding-top: 88px;
    min-height: 85vh;
    padding-bottom: 88px;

    @media only screen and (max-width: 1280px) {
      min-height: 70vh;
      padding-bottom: 4rem;
    }

    @media only screen and (max-width: 992px) {
      min-height: 70vh;
      padding-bottom: 88px;
    }

    &:not(:focus-within):not(:hover) {
      article {
        opacity: 1;
      }
    }

    article {
      margin: 48px 0;
      transition-property: background-color, border-color, color, fill, stroke,
        opacity, box-shadow, transform;
      transition: all 0.2s ease-in-out;

      @media only screen and (min-width: 992px) {
        opacity: 0.4;
      }

      a {
        display: flex;
        align-items: center;
        transition-property: background-color, border-color, color, fill, stroke,
          opacity, box-shadow, transform;
        transition: all 0.2s ease-in-out;

        .contact__icon {
          width: 120px;
          height: 120px;
          transition-property: background-color, border-color, color, fill,
            stroke, opacity, box-shadow, transform;
          transition: all 0.2s ease-in-out;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          background-color: $bg-grey;
          margin-right: 40px;
          flex-shrink: 0;

          svg {
            height: 18px;
            width: 18px;
            fill: $color-white;
          }

          @media only screen and (max-width: 992px) {
            width: 80px;
            height: 80px;
            margin-right: 20px;
          }
        }

        .contact__route {
          letter-spacing: 3px;
          text-transform: uppercase;
          font-size: 0.7rem;
          font-weight: 600;
          color: $color-white;
        }

        h3 {
          margin-top: 8px;
          line-height: 1.25;
          font-size: 1.8rem;
          font-weight: 600;
          color: $color-white;
        }
      }

      @media only screen and (min-width: 992px) {
        &:focus,
        &:hover {
          opacity: 1;

          .contact__icon {
            transform: scale(1.1);
          }
        }

        &:focus ~ &,
        &:hover ~ & {
          opacity: 0.4;
        }
      }
    }
  }

  #footer {
    position: absolute;
    bottom: 88px;

    > div {
      display: flex;
      display: -webkit-flex;
      align-content: center;

      img {
        width: 20px;
        margin-right: 0.6rem;
        margin-bottom: 2px;
      }
    }

    @media only screen and (max-width: 1280px) {
      bottom: 32px;
      padding: 0 0 0 0;

      > div {
        height: 50px;
        align-items: center;
      }
    }

    @media only screen and (max-width: 992px) {
      bottom: 0;
      width: 100%;
      left: 50%;
      transform: translateX(-50%);

      > div {
        width: 100%;
        justify-content: center;
      }
    }
  }
}
