.site-intro {
  background: black;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.intro-text {
  font-size: 1.3rem;
}

.slider {
  background: rgb(26, 26, 26);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateY(100%);
}

.hide {
  overflow: hidden;
}

.hide span {
  display: inline-block;
  transform: translateY(100%);
}
