.cursor {
  width: 6px;
  height: 6px;
  border: 1px solid #fefefe;
  border-radius: 100%;
  position: fixed;
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: 9999;
  mix-blend-mode: difference;
  transition: all 150ms ease;
  transition-property: opacity, background-color, transform, mix-blend-mode;
  background-color: #fefefe;
}

.cursor--hidden {
  opacity: 0;
}

.cursor--clicked {
  transform: translate(-50%, -50%) scale(2.5);
  background-color: transparent;
}

.cursor--link-hovered {
  transform: translate(-50%, -50%) scale(2.5);
  background-color: transparent;
}

html,
body,
a {
  cursor: none;
}
